*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family:sans-serif
}
body{
  height: 1000px;
 
}
a{
  text-decoration: none;
  color: white;
}
